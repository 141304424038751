import gsap from 'gsap';

const pageLeave = (container) => {

    let menuLayer1 = container.querySelector('.menu__layer1');
    let menuLayer2 = container.querySelector('.menu__layer2');

    const tl = gsap.timeline();

    tl
        .to(menuLayer1, { height: '100%', duration: 1, ease: 'power4.inOut'})
        .to(menuLayer2, { height: '100%', duration: 1, ease: 'power4.inOut'}, 0.2)

    return tl;

}

export default pageLeave;
