import 'select2/dist/js/select2.min'

export const templateContactData = {
    init() {
        // JavaScript to be fired on all pages
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
