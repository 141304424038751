import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const pageLayerBanner = (container) => {

    let layerContainers = container.querySelectorAll('.page-layer--banner');
    layerContainers.forEach((elem) => {
        if (elem) {
            let tl = gsap.timeline();

            let layerLeft = elem.querySelector('.page-layer--leftbar');
            let layerRight = elem.querySelector('.page-layer--rightbar');

            tl.to(layerLeft, {translateX: '-100%', scrollTrigger: {
                trigger: elem,
                start: 'top bottom',
                end: 'bottom bottom',
                scrub: true,
                markers: false,
                }});

            tl.to(layerRight, {translateX: '100%', scrollTrigger: {
                trigger: elem,
                start: 'top bottom',
                end: 'bottom bottom',
                scrub: true,
                markers: false,
                }});
        }
    });

}

export default pageLayerBanner;

